import { NgModule } from '@angular/core';
import { TypewriterComponent } from './typewriter.component';



@NgModule({
  declarations: [TypewriterComponent],
  imports: [
  ],
  exports: [TypewriterComponent]
})
export class AngularTypewriterEffectModule { }
